'use client'
 
export default function GlobalError({
  error,
  reset,
}: {
  error: Error
  reset: () => void
}) {
  return (
    <html>
      <body>
        <div className={"error_wrap"}>
          <div className={"error_box"}>
            <h2>해당 페이지 접속이 원활하지 않아 다시 접속 부탁드립니다.</h2>
            <button onClick={() => window.location.reload()}>새로고침</button>
            <button onClick={() => window.location.href="/"}>메인으로 돌아가기</button>
          </div>
        </div>
      </body>
    </html>
  )
}